/** @jsx jsx */
import { graphql } from "gatsby"
import { jsx } from "theme-ui"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Container from "../components/container"
import ActualiteCollection from "../components/actualite-collection"
import ActualiteItem from "../components/actualite-item"

const IndexPage = ({ data }) => (
  <Layout>
    <Helmet>
      <link rel="canonical" href={"https://lesfilmspelleas.com/actualite/"} />
    </Helmet>
    <SEO title="Actualités" />
    <Section.Title1 sx={{ mt: 5, mb: 4 }}>ACTUALITÉS</Section.Title1>
    <Section isGrey={false}>
      <Container isFull={true} sx={{ px: 0 }}>
        <ActualiteCollection>
          {data.actualites.nodes.map(actualite => (
            <ActualiteItem
              isFluid={true}
              key={actualite.id}
              actualite={actualite}
            />
          ))}
        </ActualiteCollection>
      </Container>
    </Section>
  </Layout>
)

export const query = graphql`
  query {
    actualites: allBubbleActualite(
      sort: { fields: Created_Date, order: DESC }
      filter: { DELETED: { ne: true } }
    ) {
      nodes {
        Description_FR
        Source
        Titre_FR
        DELETED
        PUBLIE
        Featured
        id
        Lien_URL
        Image_principale_optimized {
          childImageSharp {
            fluid(
              maxWidth: 370
              maxHeight: 370
              quality: 85
              cropFocus: CENTER
            ) {
              # ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default IndexPage
